export {ContainerLayout} from './ContainerLayout';
export type {ContainerLayoutDefinition} from './ContainerLayout';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './ContainerLayoutDefinition';
export type {SchemaType} from './ContainerLayoutDefinition';
export {ContainerLayoutPreview} from './ContainerLayoutPreview';

import {Registry} from '@backstage-components/base';
import {ContainerLayoutModule} from './ContainerLayoutModule';
import {ComponentDefinition} from './ContainerLayoutDefinition';
Registry.register(ComponentDefinition, ContainerLayoutModule);
