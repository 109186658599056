"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowVersionMessage = exports.ShowPublishedMessage = exports.ShowPublishMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model/model-helpers");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
const ShowPublishDetailType = 'Show Publish';
const ShowPublishDetail = typebox_1.Type.Object({
    after: (0, model_helpers_1.Nullable)(typebox_1.Type.String({
        title: 'Pagination Cursor',
        description: 'Cursor used to retrieve the next page of access codes',
    })),
    first: typebox_1.Type.Integer({
        title: 'Size of Pagination',
        description: 'The number of access codes to retrieve in a page',
    }),
    showId: typebox_1.Type.String({
        title: 'Show Id',
        description: 'Identifier of the Show record whose data will be published',
        format: 'uuid',
    }),
}, {
    title: ShowPublishDetailType,
    description: 'Expected shape of the Detail field of message emitted when a new ShowVersion is created',
});
exports.ShowPublishMessage = typebox_1.Type.Object({
    detail: ShowPublishDetail,
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal(ShowPublishDetailType),
});
const ShowPublishedDetailType = 'Show Published';
const ShowPublishedDetail = typebox_1.Type.Object({
    showId: typebox_1.Type.String({
        title: 'Show Id',
        description: 'Identifier of the Show record whose data will be published',
        format: 'uuid',
    }),
}, {
    title: ShowPublishedDetailType,
    description: 'Expected shape of the Detail field of message emitted when a new ShowVersion is created',
});
exports.ShowPublishedMessage = typebox_1.Type.Object({
    detail: ShowPublishedDetail,
    source: message_source_1.ShowPublishSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal(ShowPublishedDetailType),
});
exports.ShowVersionMessage = typebox_1.Type.Union([
    exports.ShowPublishMessage,
    exports.ShowPublishedMessage,
]);
