"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PutAssetsResponse = exports.PutAssetsRequest = exports.PostAssetsResponse = exports.PostAssetsRequest = exports.AssetUploadTypes = exports.GetAssetsResponse = exports.GetAssetsRequest = exports.DeleteAssetsResponse = exports.DeleteAssetsRequest = void 0;
var delete_assets_1 = require("./delete-assets");
Object.defineProperty(exports, "DeleteAssetsRequest", { enumerable: true, get: function () { return delete_assets_1.DeleteAssetsRequest; } });
Object.defineProperty(exports, "DeleteAssetsResponse", { enumerable: true, get: function () { return delete_assets_1.DeleteAssetsResponse; } });
var get_assets_1 = require("./get-assets");
Object.defineProperty(exports, "GetAssetsRequest", { enumerable: true, get: function () { return get_assets_1.GetAssetsRequest; } });
Object.defineProperty(exports, "GetAssetsResponse", { enumerable: true, get: function () { return get_assets_1.GetAssetsResponse; } });
var post_assets_1 = require("./post-assets");
Object.defineProperty(exports, "AssetUploadTypes", { enumerable: true, get: function () { return post_assets_1.AssetUploadTypes; } });
Object.defineProperty(exports, "PostAssetsRequest", { enumerable: true, get: function () { return post_assets_1.PostAssetsRequest; } });
Object.defineProperty(exports, "PostAssetsResponse", { enumerable: true, get: function () { return post_assets_1.PostAssetsResponse; } });
var put_assets_1 = require("./put-assets");
Object.defineProperty(exports, "PutAssetsRequest", { enumerable: true, get: function () { return put_assets_1.PutAssetsRequest; } });
Object.defineProperty(exports, "PutAssetsResponse", { enumerable: true, get: function () { return put_assets_1.PutAssetsResponse; } });
