export {CarouselComponent} from './CarouselComponent';
export type {CarouselComponentDefinition} from './CarouselComponent';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './CarouselDefinition';
export type {SchemaType} from './CarouselDefinition';
export {CarouselPreviewComponent} from './CarouselPreviewComponent';

import {Registry} from '@backstage-components/base';
import {CarouselModule} from './CarouselModule';
import {ComponentDefinition} from './CarouselDefinition';
Registry.register(ComponentDefinition, CarouselModule);
