import {defaultFieldData, SchemaType} from './StyleLayoutDefinition';
import {WithHtmlId, ModuleNode} from '@backstage-components/base';
import {VFC} from 'react';
import {css} from '@emotion/css';
import styled from '@emotion/styled';

export type StyleProps = SchemaType;

type StyledContainerProps = Pick<
  StyleProps,
  | 'backgroundImage'
  | 'backgroundRepeat'
  | 'backgroundPosition'
  | 'backgroundSize'
  | 'styleAttr'
>;

const Styled = styled.div<StyledContainerProps>`
  ${({
    backgroundImage,
    backgroundRepeat,
    backgroundPosition,
    backgroundSize,
    styleAttr,
  }) =>
    `
    ${backgroundImage && `background-image: url(${backgroundImage})`};
    ${`background-repeat: ${
      backgroundRepeat ?? defaultFieldData.backgroundRepeat
    }`};
    ${`background-position: ${
      backgroundPosition ?? defaultFieldData.backgroundPosition
    }`};
    ${`background-size: ${backgroundSize ?? defaultFieldData.backgroundSize}`};
    ${styleAttr}`};
`;

export const StyleLayoutPreview: VFC<
  ModuleNode<'Style', StyleProps> & WithHtmlId
> = ({module: {props, id}, slotComponent: Slot}) => {
  const cssFromRestProps = css`
    padding: ${props.padding};
    margin: ${props.margin};
    background-color: ${props.backgroundColor};
    max-width: ${props.maxWidth};
    min-height: ${props.minHeight};
  `;
  return (
    <Styled
      id={id}
      className={cssFromRestProps}
      backgroundImage={props.backgroundImage}
      backgroundPosition={props.backgroundPosition}
      backgroundRepeat={props.backgroundRepeat}
      backgroundSize={props.backgroundSize}
      styleAttr={props.styleAttr}
    >
      <Slot slotName="items" orientation={props.layout?.orientation} />
    </Styled>
  );
};
