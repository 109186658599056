export const arrayToLookup = <T, Key extends keyof T>(
  arr: T[],
  key: Key
): Record<string, T> => {
  const output: Record<string, T> = {};
  return arr.reduce((memo, x) => {
    const k = String(x[key]);
    memo[k] = x;
    return memo;
  }, output);
};

/**
 * Determine if a given `JSONValue` is an object.
 * @param o to be checked.
 * @returns `true` if the given value is an object (not an array or primitive).
 */
export function isJSONObject(o: JSONValue): o is JSONObject {
  return typeof o === 'object' && o !== null && !Array.isArray(o);
}

/**
 * Debounce function
 * @param fn - the function the debounce will call
 * @param ms - how often to run the debounced function
 * @returns {[(args: A) => Promise<R>, () => void]} - [the debounced function, the teardown/destroy function]
 */
export function debounce<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number
): [(args: A) => Promise<R>, () => void] {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(args));
      }, ms);
    });

  const teardown = (): void => clearTimeout(timer);

  return [debouncedFunc, teardown];
}
