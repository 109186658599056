import {LayoutModule, useShowInstructions} from '@backstage-components/base';
import {css} from '@emotion/react';
import {Fragment, VFC, useState, useMemo} from 'react';
import {useSubscription} from 'observable-hooks';
import {reactName, SchemaType, instructions} from './CarouselDefinition';
import {
  Navigation,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow,
  EffectFlip,
  EffectCreative,
  EffectCards,
  Scrollbar,
  A11y,
} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import type {Swiper as SwiperInterface} from 'swiper';
import * as Styled from './Carousel.styled';

export type CarouselComponentDefinition = LayoutModule<'Carousel', SchemaType>;

export const CarouselComponent: VFC<CarouselComponentDefinition> = (
  definition
) => {
  const [swiper, setSwiper] = useState<SwiperInterface | null>(null);
  const {observable, broadcast} = useShowInstructions(instructions, definition);
  useSubscription(observable, (inst) => {
    // Update the slide index with instructions
    if (inst.type === `${reactName}:slide-change`) {
      if (swiper) {
        swiper.slideTo(parseInt(inst.meta.slideIndex, 10), props.speed);
      }
    }
  });

  // Include at least `definition.style` as part of the outer-most component
  // returned. `definition.style` is how layouts pass size information.
  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;
  const {props, cid, id} = definition;
  const {
    slidesPerView,
    loop,
    spaceBetween,
    autoplay,
    pagination,
    navigation,
    effect,
    styleOptions,
  } = props;

  const {slotRenderer: Component = () => <Fragment />} = definition;
  const renderedChildren = useMemo(() => {
    const {items, ...children} = definition.slots ?? {items: []};
    const components = Object.values(children)
      .flatMap((element) => {
        if (Array.isArray(element)) {
          return element;
        } else if (typeof element !== 'undefined') {
          return [element];
        } else {
          return [];
        }
      })
      .concat(items ?? []);
    return components.map((component) => {
      return (
        <SwiperSlide key={`${component.path.join(':')}:${component.mid}`}>
          <Component {...component} />
        </SwiperSlide>
      );
    });
  }, [Component, definition.slots]);

  return (
    <Styled.CarouselWrapper {...styleOptions} css={styles} id={id}>
      <Swiper
        onSwiper={(swiper) => setSwiper(swiper)}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        loop={loop}
        autoplay={autoplay}
        pagination={
          pagination && {
            clickable: true,
          }
        }
        navigation={navigation}
        modules={[
          Pagination,
          Navigation,
          Scrollbar,
          A11y,
          EffectFade,
          EffectCube,
          EffectCoverflow,
          EffectFlip,
          EffectCreative,
          EffectCards,
        ]}
        className={`carousel-${cid}`}
        centeredSlides
        centeredSlidesBounds
        effect={effect}
        onSlideChange={(s) => {
          broadcast({
            type: `${reactName}:on-slide-change`,
            meta: {slideIndex: s.activeIndex.toString()},
          });
        }}
      >
        {renderedChildren}
      </Swiper>
    </Styled.CarouselWrapper>
  );
};
