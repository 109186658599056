export {StyleLayout} from './StyleLayout';
export type {StyleLayoutDefinition} from './StyleLayout';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './StyleLayoutDefinition';
export type {SchemaType} from './StyleLayoutDefinition';
export {StyleLayoutPreview} from './StyleLayoutPreview';

import {Registry} from '@backstage-components/base';
import {StyleLayoutModule} from './StyleLayoutModule';
import {ComponentDefinition} from './StyleLayoutDefinition';
Registry.register(ComponentDefinition, StyleLayoutModule);
