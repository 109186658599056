import {Alert, AlertIcon, Box} from '@chakra-ui/react';

import {SchemaType, name} from './ModalDefinition';
import {ModuleCollapse, ModuleNode} from '@backstage-components/base';
import {VFC} from 'react';

export type ModalProps = SchemaType;

export const ModalPreviewComponent: VFC<ModuleNode<'Modal', ModalProps>> = ({
  module: {props},
  slotComponent: Slot,
}) => {
  return (
    <ModuleCollapse moduleName={name}>
      <Box
        backgroundImage={props?.modalBodyProps?.backgroundImage}
        backgroundRepeat={props?.modalBodyProps?.backgroundRepeat}
        backgroundPosition={props?.modalBodyProps?.backgroundPosition}
        backgroundSize={props?.modalBodyProps?.backgroundSize}
        position="relative"
      >
        <Alert status="info">
          <AlertIcon />
          Modal: This will not appear unless instructions are set. Use the flow
          editor to set this modal to open.
        </Alert>
        <Slot slotName="items" />
      </Box>
    </ModuleCollapse>
  );
};
