"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PutAssetsResponse = exports.PutAssetsRequest = void 0;
const typebox_1 = require("@sinclair/typebox");
const data_1 = require("../../data");
exports.PutAssetsRequest = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    fileName: typebox_1.Type.String(),
});
exports.PutAssetsResponse = typebox_1.Type.Object({
    asset: data_1.Asset,
});
