import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {ModalComponent, ModalComponentDefinition} from './ModalComponent';
import {ComponentDefinition} from './ModalDefinition';

type Kind = ModalComponentDefinition['component'];

type Props = ModalComponentDefinition['props'];

export const ModalModule: Module<Kind, Props> = {
  isContent(value): value is ModalComponentDefinition {
    return false;
  },
  isLayout(value): value is ModalComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  tryRender(definition) {
    if (ModalModule.isContent(definition)) {
      return React.createElement(ModalComponent, definition);
    } else {
      return NextModule;
    }
  },
};
