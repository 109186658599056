"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InstructionSource = exports.ModuleIdentifiers = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("./model-helpers");
/**
 * ModuleIdentifiers defines the shape of data to identify a specific module in
 * the page.
 */
exports.ModuleIdentifiers = typebox_1.Type.Optional(typebox_1.Type.Object({
    id: typebox_1.Type.String({
        title: 'ID',
        description: `Id that will be used in the DOM to identify the module.`,
    }),
    mid: typebox_1.Type.String({
        title: 'Module Id',
        description: `Identifier for the module "glue" attaching cid to the module's parent`,
    }),
    cid: typebox_1.Type.String({
        title: 'Core Id',
        description: 'Identifier for the core holding configuration for the attached component',
    }),
    gid: (0, model_helpers_1.Nullable)(typebox_1.Type.String({
        title: 'Group Id',
        description: 'Identifier for the core to which the identified module belongs',
    })),
    groupModuleId: (0, model_helpers_1.Nullable)(typebox_1.Type.String({
        title: 'Group Module Id',
        description: 'Identifier for the group instance to which the identified module belongs',
    })),
    path: typebox_1.Type.Array(typebox_1.Type.String(), {
        description: 'Path from page "root" to the identified module',
    }),
}));
/**
 * Union of potential broadcast sources for an Instruction.
 */
exports.InstructionSource = typebox_1.Type.Union([exports.ModuleIdentifiers, typebox_1.Type.Literal('flow'), typebox_1.Type.Literal('internal')], {
    title: 'Source of Instruction',
    description: 'Broadcast source for the Instruction',
});
