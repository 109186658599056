import {
  applyTween,
  LayoutModule,
  ModuleProperties,
  useShowInstructions,
  scrollTo,
  useMotionOptions,
} from '@backstage-components/base';
import {Box, type BoxProps} from '@chakra-ui/react';
import {cx, css} from '@emotion/css';
import {motion} from 'framer-motion';
import React, {Fragment, useMemo, useState, VFC} from 'react';
import {useSubscription} from 'observable-hooks';
import {reactName, instructions, SchemaType} from './ContainerLayoutDefinition';

export interface IContainerProps extends ModuleProperties, SchemaType {}

export type ContainerLayoutDefinition = LayoutModule<
  typeof reactName,
  SchemaType
>;

export const TEST_ID = `${reactName}-test`;

const MotionBox = motion<BoxProps>(Box);

export const ContainerLayout: VFC<ContainerLayoutDefinition> = (definition) => {
  const {slotRenderer: Component = () => <Fragment />} = definition;
  const {props} = definition;
  const {animationStates} = props;
  const [activeVariant, setActiveVariant] = useState<string | undefined>(
    undefined
  );
  const motionOptions = useMotionOptions(animationStates);

  const renderedChildren = useMemo(() => {
    const {items, ...children} = definition.slots ?? {items: []};
    const components = Object.values(children)
      .flatMap((element) => {
        if (Array.isArray(element)) {
          return element;
        } else if (typeof element !== 'undefined') {
          return [element];
        } else {
          return [];
        }
      })
      .concat(items ?? []);

    return components.map((component) => {
      const componentStyle = `${component.style + ';' || ''}`;
      return (
        <Component
          key={`${component.path.join(':')}:${component.mid}`}
          {...component}
          style={componentStyle}
        />
      );
    });
  }, [Component, definition.slots]);

  const {observable} = useShowInstructions(instructions, definition);

  useSubscription(observable, (inst) => {
    if (inst.type === 'Container:animationState') {
      applyTween(inst, animationStates, setActiveVariant);
    } else if (inst.type === 'Container:scrollTo') {
      const {elementId, anchorElId, scrollX, scrollY} = inst.meta;
      scrollTo({elementId, anchorElId, scrollX, scrollY});
    }
  });

  const styleClassName = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;
  return (
    <MotionBox
      id={definition.id}
      data-testid={TEST_ID}
      className={cx(styleClassName, definition.mid)}
      {...motionOptions}
      animate={activeVariant}
    >
      {renderedChildren}
    </MotionBox>
  );
};
