export {ModalComponent} from './ModalComponent';
export type {ModalComponentDefinition} from './ModalComponent';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './ModalDefinition';
export type {SchemaType} from './ModalDefinition';
export {ModalPreviewComponent} from './ModalPreviewComponent';

import {Registry} from '@backstage-components/base';
import {ModalModule} from './ModalModule';
import {ComponentDefinition} from './ModalDefinition';
Registry.register(ComponentDefinition, ModalModule);
