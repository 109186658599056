import {Fragment, VFC} from 'react';
import {Helmet} from 'react-helmet-async';
import DynamicLayoutComponent from './components/dynamicLayoutComponent';
import {PageData} from './hooks';

export const PageRoute: VFC<PageRouteProps> = (props) => {
  const {page} = props;
  const modules = page?.modules ?? [];
  return (
    <Fragment>
      <Helmet>
        <title>{page.title}</title>
      </Helmet>
      <DynamicLayoutComponent components={modules} />
    </Fragment>
  );
};

interface PageRouteProps {
  showId?: string;
  page: PageData;
}
