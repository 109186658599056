import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {StyleLayout, StyleLayoutDefinition} from './StyleLayout';
import {ComponentDefinition, SchemaType} from './StyleLayoutDefinition';

type Kind = StyleLayoutDefinition['component'];

type Props = SchemaType;

export const StyleLayoutModule: Module<Kind, Props> = {
  isContent(value): value is StyleLayoutDefinition {
    return false;
  },
  isLayout(value): value is StyleLayoutDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  tryRender(definition) {
    if (StyleLayoutModule.isContent(definition)) {
      return React.createElement(StyleLayout, definition);
    } else {
      return NextModule;
    }
  },
};
