import {extractModuleIdentifiers, ModuleNode} from '@backstage-components/base';
import React, {VFC} from 'react';
import {SchemaType} from './AccessCodeDefinition';
import {AccessCodeComponent, IAccessCodeProps} from './AccessCodeComponent';

export type AccessCodeProps = SchemaType & IAccessCodeProps;

export const AccessCodePreviewComponent: VFC<
  ModuleNode<'AccessCode', AccessCodeProps>
> = (definition) => {
  const {component, props, config} = definition.module;
  return (
    <AccessCodeComponent
      component={component}
      config={config}
      {...extractModuleIdentifiers(definition.module)}
      props={props}
    />
  );
};
