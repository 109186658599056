import {LayoutModule, Orientation} from '@backstage-components/base';
import {css, cx} from '@emotion/css';
import {CSSProperties, Fragment, useMemo, VFC} from 'react';
import {SchemaType} from './StyleLayoutDefinition';

/**
 * These are defined on `Stacked` layout but not style. But the implementations
 * for now mirror one another.
 */
interface IStyleProps {
  displayName?: string;
  height?: string | number;
  maxHeight?: string | number;
  gap?: string;
  layout?: LayoutProps;
  autoLayout?: boolean;
}

/**
 * These are defined on `Stacked` layout but not style. But the implementations
 * for now mirror one another.
 */
interface LayoutProps {
  orientation: Orientation;
  preset?:
    | 'center'
    | 'start'
    | 'end'
    | 'space-around'
    | 'space-evenly'
    | 'space-between';
  align?: 'center' | 'start' | 'end';
}

export type StyleLayoutDefinition = LayoutModule<
  'Style',
  IStyleProps & SchemaType
>;

export const StyleLayout: VFC<StyleLayoutDefinition> = (definition) => {
  const {id, slotRenderer: Component = () => <Fragment />, props} = definition;

  const renderedChildren = useMemo(() => {
    const {items, ...children} = definition.slots ?? {items: []};
    const components = Object.values(children)
      .flatMap((element) => {
        if (Array.isArray(element)) {
          return element;
        } else if (typeof element !== 'undefined') {
          return [element];
        } else {
          return [];
        }
      })
      .concat(items ?? []);
    const itemsLength = 100 / components.length;
    return components.map((component) => {
      const enableFlexShorthand =
        'autoLayout' in props && props.autoLayout === true;
      const flexShorthand = enableFlexShorthand
        ? `flex: 1 1 ${itemsLength}%;`
        : '';
      const componentStyle = `${component.style + ';' || ''} ${flexShorthand}`;
      return (
        <Component
          key={`${component.path.join(':')}:${component.mid}`}
          {...component}
          style={componentStyle}
        />
      );
    });
  }, [Component, props, definition.slots]);

  let backgroundClassName: string | undefined;

  if (definition.component === 'Style' || definition.component === 'Stacked') {
    backgroundClassName = css`
      background-position: ${props.backgroundPosition || 'center'};
      background-repeat: ${props.backgroundRepeat || 'no-repeat'};
      background-size: ${props.backgroundSize || 'cover'};
      background-image: url(${props.backgroundImage});
      background-color: ${props.backgroundColor};
      background-attachment: ${props.backgroundAttachment};
    `;
  }

  const {preset, align, orientation} = props.layout || {
    orientation: 'vertical',
  };

  const layoutStyle = computeLayout(preset, align, orientation);
  const layoutClassName = css`
    margin: ${props.margin || '0'};
    padding: ${props.padding || '0'};
    gap: ${props.gap || '0'};
  `;

  const sizeClassName = css`
    ${props.height && `height: ${props.height}`};
    ${props.minHeight && `min-height: ${props.minHeight}`};
    ${props.maxHeight && `max-height: ${props.maxHeight}`};
    ${props.maxWidth && `max-width: ${props.maxWidth}`};
  `;

  const renderLayout: boolean =
    renderedChildren.length > 0 ||
    definition.component === 'Style' ||
    definition.component === 'Stacked';

  const styleClassName = css`
    ${definition.style}
    ${props.styleAttr}
  `;
  if (renderLayout) {
    return (
      <div
        id={id}
        data-testid={props.displayName}
        data-display-name={props.displayName}
        className={cx(
          css({...layoutStyle}),
          backgroundClassName,
          layoutClassName,
          styleClassName,
          sizeClassName,
          definition.mid
        )}
      >
        {renderedChildren}
      </div>
    );
  } else {
    return <Fragment />;
  }
};

/**
 * @param preset settings that will return a corresponding flex layout.
 * @param align sets the `align-items` property.
 * @param orientation used to calculate flex-direction.
 * @returns a flex layout setting based on one of either `preset`, `flexbox` or `orientation`, or else returns `undefined`.
 */
const computeLayout = (
  preset:
    | 'center'
    | 'start'
    | 'end'
    | 'space-around'
    | 'space-evenly'
    | 'space-between'
    | undefined,
  align: 'center' | 'start' | 'end' | undefined,
  orientation: Orientation
): CSSProperties | undefined => {
  const direction = orientation === 'horizontal' ? 'row' : 'column';
  if (preset) {
    if (preset) {
      if (preset === 'start') {
        return {
          display: 'flex',
          flexDirection: direction,
          justifyContent: 'flex-start',
          alignItems: align,
        };
      } else if (preset === 'end') {
        return {
          display: 'flex',
          flexDirection: direction,
          justifyContent: 'flex-end',
          alignItems: align,
        };
      } else {
        return {
          display: 'flex',
          flexDirection: direction,
          justifyContent: preset,
          alignItems: align,
        };
      }
    }
  }
  if (orientation === 'vertical') {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: align,
    };
  } else if (orientation === 'horizontal') {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: align,
    };
  } else {
    return undefined;
  }
};
