import {ModuleNode} from '@backstage-components/base';
import {Box} from '@chakra-ui/react';
import {css} from '@emotion/react';
import React, {VFC} from 'react';
import {SchemaType} from './ContainerLayoutDefinition';

export type ContainerLayoutProps = SchemaType;

export const ContainerLayoutPreview: VFC<
  ModuleNode<'Container', ContainerLayoutProps>
> = ({module: {props, id}, slotComponent: Slot}) => {
  return (
    <Box
      id={id}
      css={css`
        ${props.styleAttr}
      `}
    >
      <Slot slotName="items" />
    </Box>
  );
};
