import {useEffect, useState} from 'react';
import {extractModuleIdentifiers, ModuleIdentifiers} from '../helpers';

/**
 * Supplies the memoized set of properties of `ModuleIdentifiers` so that the
 * result only changes when the member values of the given `moduleIdentifiers`
 * change.
 * @param input ...or any object that implements `ModuleIdentifiers`
 * @returns ModuleIdentifiers
 */
export const useModuleIdentifiers = <Input extends ModuleIdentifiers>(
  input?: Input
): ModuleIdentifiers | undefined => {
  const [identifiers, setIdentifiers] = useState<ModuleIdentifiers | undefined>(
    input ? extractModuleIdentifiers(input) : undefined
  );
  useEffect(() => {
    setIdentifiers((current) => {
      if (typeof current === 'undefined' && typeof input === 'undefined') {
        return current;
      } else if (typeof current === 'undefined') {
        return input ? extractModuleIdentifiers(input) : undefined;
      } else if (typeof input === 'undefined') {
        return current;
      } else {
        if (
          current.cid === input.cid &&
          current.gid === input.gid &&
          current.groupModuleId === input.groupModuleId &&
          current.id === input.id &&
          current.mid === input.mid &&
          current.path.length === input.path.length &&
          current.path.every((c, i) => c === input.path[i])
        ) {
          return current;
        } else {
          return extractModuleIdentifiers(input);
        }
      }
    });
  }, [input]);
  return identifiers;
};
