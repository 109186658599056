"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coreTypes = void 0;
/*
 * If this set ever increases beyond default|group, be sure to update the logic
 * for `MutationCreatePage` since copying currently expects only these two types.
 */
exports.coreTypes = {
    default: 'default',
    group: 'group',
};
