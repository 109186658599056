import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {
  CarouselComponent,
  CarouselComponentDefinition,
} from './CarouselComponent';
import {ComponentDefinition} from './CarouselDefinition';

type Kind = CarouselComponentDefinition['component'];

type Props = CarouselComponentDefinition['props'];

export const CarouselModule: Module<Kind, Props> = {
  isContent(value): value is CarouselComponentDefinition {
    return false;
  },
  isLayout(value): value is CarouselComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  tryRender(definition) {
    if (CarouselModule.isLayout(definition)) {
      return React.createElement(CarouselComponent, definition);
    } else {
      return NextModule;
    }
  },
};
