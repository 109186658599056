export {StackLayout} from './StackLayout';
export type {StackLayoutDefinition} from './StackLayout';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './StackLayoutDefinition';
export type {SchemaType} from './StackLayoutDefinition';
export {StackLayoutPreview} from './StackLayoutPreview';

import {Registry} from '@backstage-components/base';
import {StackLayoutModule} from './StackLayoutModule';
import {ComponentDefinition} from './StackLayoutDefinition';
Registry.register(ComponentDefinition, StackLayoutModule);
