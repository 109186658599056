import {defaultFieldData, SchemaType} from './StackLayoutDefinition';
import {ModuleNode, WithHtmlId} from '@backstage-components/base';
import {VFC} from 'react';
import styled from '@emotion/styled';
import {computeLayout} from './StackLayout';

export type StackedProps = SchemaType;

type StyledContainerProps = Pick<
  StackedProps,
  | 'backgroundImage'
  | 'backgroundColor'
  | 'backgroundRepeat'
  | 'backgroundPosition'
  | 'backgroundSize'
  | 'maxHeight'
  | 'minHeight'
  | 'height'
  | 'styleAttr'
>;

const Styled = styled.div<StyledContainerProps>`
  ${({
    backgroundImage,
    backgroundColor,
    backgroundRepeat,
    backgroundPosition,
    backgroundSize,
    maxHeight,
    minHeight,
    height,
    styleAttr,
  }) =>
    `
    ${backgroundImage && `background-image: url(${backgroundImage})`};
    ${backgroundColor && `background-color: ${backgroundColor}`};
    ${`background-repeat: ${
      backgroundRepeat ?? defaultFieldData.backgroundRepeat
    }`};
    ${`background-position: ${
      backgroundPosition ?? defaultFieldData.backgroundPosition
    }`};
    ${`background-size: ${backgroundSize ?? defaultFieldData.backgroundSize}`};
    ${minHeight && `min-height: ${minHeight}`};
    ${maxHeight && `max-height: ${maxHeight}`};
    ${height && `height: ${height}`};
    ${styleAttr}`};
`;

export const StackLayoutPreview: VFC<
  ModuleNode<'Stacked', StackedProps> & WithHtmlId
> = ({module: {props, id}, slotComponent: Slot}) => {
  const {preset, align, orientation} = props.layout || {
    orientation: 'vertical',
  };
  const layoutStyle = computeLayout(preset, align, orientation);
  return (
    <Styled
      id={id}
      css={{...layoutStyle}}
      backgroundImage={props.backgroundImage}
      backgroundColor={props.backgroundColor}
      backgroundPosition={props.backgroundPosition}
      backgroundRepeat={props.backgroundRepeat}
      backgroundSize={props.backgroundSize}
      minHeight={props.minHeight}
      maxHeight={props.maxHeight}
      height={props.height}
      styleAttr={props.styleAttr}
    >
      <Slot
        slotName="items"
        orientation={props.layout?.orientation}
        autoLayout={props.autoLayout}
      />
    </Styled>
  );
};
