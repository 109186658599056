import {ModuleCollapse, ModuleNode} from '@backstage-components/base';
import {Alert, AlertIcon, Box} from '@chakra-ui/react';
import {VFC} from 'react';
import {SchemaType, name} from './CarouselDefinition';

export type CarouselProps = SchemaType;

export const CarouselPreviewComponent: VFC<
  ModuleNode<'Carousel', CarouselProps>
> = ({slotComponent: Slot}) => {
  return (
    <ModuleCollapse moduleName={name}>
      <Box>
        <Alert status="info">
          <AlertIcon />
          Carousel: The carousel will only appear on your site, use the layout
          editor to add your slides.
        </Alert>
        <Box border="1px dashed gray" p={6}>
          <Alert status="warning">
            Slides: Add slides here, slides should be siblings of one another
          </Alert>
          <Slot slotName="slides" />
        </Box>
      </Box>
    </ModuleCollapse>
  );
};
