import {storage} from '../../../helpers';
import {NodeInstanceData} from '../../../types';
import {FlowExportObject} from './reactFlow.types';

type OverallNodeData = {
  /**
   * History id of the tab labels and disabled/enabled state.
   * Note: This does id does not change when content of individual
   * tabs change.
   */
  id?: string;
  /** Reliable key for versioning all tab data */
  key?: string;
  /**
   * Flow definitions grouped into an array of objects, authored in
   * our tabbed Flow Editor
   */
  tabs: NodeTab[];
};
type NodeTab = {
  tabName: string;
  /** The stable reference to this tab of flows */
  tabId: string;
  /** `tabId` has history stored. This is the id of this historical instance */
  id: string;
  data: FlowExportObject<NodeInstanceData>;
};

export const getData = (): OverallNodeData => {
  if (typeof storage === 'undefined') {
    return {tabs: []};
  }

  const flowData: OverallNodeData = JSON.parse(
    storage.getItem('flows') || '{"tabs": []}'
  );

  /*
   * It is more reliable to derive the key while reading instead of when the data
   * is written to `storage`. We would otherwise need to ensure that all code paths
   * that write this `storage` value properly add the `key`.
   * NOTE: `key` will not be visible in localStorage.
   */
  flowData.key = [flowData.id ?? '']
    .concat(flowData.tabs.map((x) => x.id))
    .join(';');

  return flowData;
};
