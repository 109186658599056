import {
  QueryShowInstructions,
  type ShowInstructionsQuery as GetShowInstructionsQuery,
  type ShowInstructionsQueryVariables as GetShowInstructionsVariables,
} from '@backstage/attendee-ui-types';
import {AttendeeProvider} from '@backstage-components/attendee-container';
import type {
  AppPage,
  GetShowInstructionsResult,
} from '@backstage-components/base';
import {ShowInstructionsProvider} from '@backstage-components/base';
import {FC} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {client} from './apollo';
import {useAnalytics} from './hooks';

const fetchInstructions = async (
  id: string,
  sinceInstructionId: string | null = null
): Promise<GetShowInstructionsResult> => {
  return client
    .query<GetShowInstructionsQuery, GetShowInstructionsVariables>({
      query: QueryShowInstructions,
      variables: {showId: id, sinceInstructionId},
      context: {showId: id},
      fetchPolicy:
        typeof sinceInstructionId === 'undefined' ? 'cache-first' : 'no-cache',
    })
    .then((result) => {
      return {
        data: result.data,
        error: result.error,
      };
    });
};

const noopFetchInstructions =
  async (): Promise<GetShowInstructionsResult> => ({});

export const AppContext: FC<AppContextProps> = ({
  appPages,
  children,
  domainName,
  showId = FALLBACK_SHOW_ID,
}) => {
  const instructionFetcher =
    showId === FALLBACK_SHOW_ID ? noopFetchInstructions : fetchInstructions;
  const analytics = useAnalytics();
  return (
    <ShowInstructionsProvider
      analyticsEndpoint={analytics?.endpoint}
      analyticsToken={analytics?.token}
      appPages={appPages}
      domainName={domainName ?? 'unknown'}
      fetchInstructions={instructionFetcher}
      showId={showId}
    >
      <AttendeeProvider client={client} showId={showId}>
        <HelmetProvider>{children}</HelmetProvider>
      </AttendeeProvider>
    </ShowInstructionsProvider>
  );
};

const FALLBACK_SHOW_ID = '00000000-0000-0000-0000-000000000000';

interface AppContextProps {
  appPages: AppPage[];
  /** Domain on which the `showId` was viewed */
  domainName?: string;
  /**
   * The id of the show whose instructions will be received. Falls back to an
   * impossible UUID.
   * @default '00000000-0000-0000-0000-000000000000'
   */
  showId: string | undefined;
}
