"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowPublishServiceMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const show_publish_1 = require("./show-publish");
/**
 * Union of any messages produced to or consumed from the Event Bus by
 * `@backstage-services/site-publish`.
 */
exports.ShowPublishServiceMessage = typebox_1.Type.Union(show_publish_1.ShowVersionMessage.anyOf);
