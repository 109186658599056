"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isJSONValue = exports.isJSONObject = exports.computeId = exports.mergeProps = exports.getModuleProps = exports.familyTree = exports.computePath = exports.isModuleVariables = exports.coreTypes = void 0;
var coreTypes_1 = require("./coreTypes");
Object.defineProperty(exports, "coreTypes", { enumerable: true, get: function () { return coreTypes_1.coreTypes; } });
var moduleTree_1 = require("./moduleTree");
Object.defineProperty(exports, "isModuleVariables", { enumerable: true, get: function () { return moduleTree_1.isModuleVariables; } });
Object.defineProperty(exports, "computePath", { enumerable: true, get: function () { return moduleTree_1.computePath; } });
Object.defineProperty(exports, "familyTree", { enumerable: true, get: function () { return moduleTree_1.familyTree; } });
Object.defineProperty(exports, "getModuleProps", { enumerable: true, get: function () { return moduleTree_1.getModuleProps; } });
Object.defineProperty(exports, "mergeProps", { enumerable: true, get: function () { return moduleTree_1.mergeProps; } });
Object.defineProperty(exports, "computeId", { enumerable: true, get: function () { return moduleTree_1.computeId; } });
var json_1 = require("./json");
Object.defineProperty(exports, "isJSONObject", { enumerable: true, get: function () { return json_1.isJSONObject; } });
Object.defineProperty(exports, "isJSONValue", { enumerable: true, get: function () { return json_1.isJSONValue; } });
