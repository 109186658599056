import {useRef} from 'react';
import {v4} from 'uuid';
import {config} from '../../config';
import {
  isLivePreviewHost,
  isLocalhost,
  isPreviewDomain,
} from './domain-helpers';

interface AnalyticsProps {
  /** URL to which instructions will be transmitted as analytics */
  endpoint: string;
  /** Token used to identify a specific guest anonymously */
  token?: string;
}

/**
 * Read analytics token from storage, if one does not exist create one. If the
 * current domain should not send analytics provides `undefined`.
 */
export function useAnalytics(): AnalyticsProps | undefined {
  const location = typeof window === 'undefined' ? undefined : window.location;
  const key = 'lcd/analytics-token';
  const result = useRef<AnalyticsProps>({
    endpoint: `${config.endpointBase}/attendee/analytics`,
    token: localStorage.getItem(key) ?? undefined,
  });
  if (
    isLivePreviewHost(config, location) ||
    (isPreviewDomain(config, location) && !isLocalhost(config, location))
  ) {
    return undefined;
  }
  if (typeof result.current.token === 'undefined') {
    const token =
      typeof window?.crypto?.randomUUID === 'function'
        ? crypto.randomUUID()
        : v4();
    localStorage.setItem(key, token);
    result.current.token = token;
  }
  return result.current;
}
