"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAssetsResponse = exports.GetAssetsRequest = void 0;
const typebox_1 = require("@sinclair/typebox");
const data_1 = require("../../data");
const PrimaryAssetRequest = typebox_1.Type.Object({
    type: typebox_1.Type.Literal('asset'),
    ids: typebox_1.Type.Array(typebox_1.Type.String()),
});
const SupplementalAssetRequest = typebox_1.Type.Object({
    type: typebox_1.Type.Union([
        typebox_1.Type.Literal('audio-config'),
        typebox_1.Type.Literal('caption-config'),
    ]),
    ids: typebox_1.Type.Array(typebox_1.Type.String()),
    configAssetId: typebox_1.Type.String(),
});
exports.GetAssetsRequest = typebox_1.Type.Union([
    PrimaryAssetRequest,
    SupplementalAssetRequest,
]);
exports.GetAssetsResponse = typebox_1.Type.Union([
    typebox_1.Type.Object({ asset: typebox_1.Type.Array(data_1.Asset) }),
    typebox_1.Type.Object({ audioConfig: typebox_1.Type.Array(data_1.AudioConfig) }),
    typebox_1.Type.Object({ captionConfig: typebox_1.Type.Array(data_1.CaptionConfig) }),
]);
