"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteAssetsResponse = exports.DeleteAssetsRequest = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.DeleteAssetsRequest = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(typebox_1.Type.String()),
});
exports.DeleteAssetsResponse = typebox_1.Type.Object({
    count: typebox_1.Type.Integer(),
});
